
import Vue, { PropType } from 'vue';
// @ts-ignore
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import ArticleCardSimple from '@/components/lh-ui/ArticleCard/ArticleCardSimple/ArticleCardSimple.vue';

import {
  CustomWindow,
  TSportsArticle,
  fetchSportsArticleData,
} from '~/utils/sports';
import { createScriptIfNeeded } from '~/utils/embeds';

declare const window: CustomWindow;

type TSportsData = {
  articlesSports: Array<TSportsArticle>;
  pagination: null | string;
};

export default Vue.extend({
  name: 'SportsWidget',
  components: {
    ArticleCardSimple,
    IntersectionObserver,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
  },

  data (): TSportsData {
    return {
      articlesSports: [],
      pagination: null,
    };
  },

  async mounted () {
    // ждем загрузки виджета, чтобы был доступ к SPRTS
    await createScriptIfNeeded('/js/sports/sports.js');

    window.addEventListener(window.SPRTS?.senderEventName, async () => {
      try {
        const sportArticleData = await fetchSportsArticleData();
        const clientWidth = screen.width;
        if (clientWidth < 768) {
          this.articlesSports = sportArticleData?.items?.slice(0, 4);
        } else if (clientWidth >= 768 && clientWidth <= 1024) {
          this.articlesSports = sportArticleData?.items?.slice(0, 6);
        } else {
          this.articlesSports = sportArticleData?.items?.slice(0, 8);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    });
  },

  beforeDestroy () {
    window.removeEventListener(
      window.SPRTS?.senderEventName,
      fetchSportsArticleData,
    );
  },

  methods: {
    sendAnalyticView () {
      const contentItems = this.articlesSports.map((article: TSportsArticle) => {
        return { id: article.id, type: article.type };
      });
      window.SPRTS.sendAnalytics({
        content_list: contentItems,
        action: 'teaser_view',
      });
    },
    sendAnalyticsClickCard (article: TSportsArticle) {
      window.SPRTS.sendAnalytics({
        content: { id: article.id, type: article.type },
        action: 'teaser_click',
      });

      this.$sendAnalyticsSnowPlow({
        event_name: 'Клик_Рекблок Sports',
        par3: article.url,
      });

      this.$sendYandexMetrika({
        level1: 'Клик_Рекблок Sports',
        level4: article.url,
      });
    },
  },
});
