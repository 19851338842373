
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

import { throttle } from 'throttle-debounce';
import ArticleCardSmall from '@/components/lh-ui/ArticleCard/ArticleCardSmall/ArticleCardSmall.vue';

import {
  FETCH_COMMENTS_COUNT,
  FETCH_VIEWS_COUNT,
  GET_AND_SET_FAVORITES,
} from '~/constants';
import FavoriteMethodsMixin from '~/mixins/FavoriteMethodsMixin';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import ArticleCardContainerMixin from '~/mixins/ArticleCardContainerMixin';
import { getFullUrl } from '~/utils';

import { isHomePage, isArchivePage, isTopPage, isHealthMain } from '~/router';
import PercentageViewed from '~/utils/percentageViewed';
import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';

type thresholdOptions = {
  [key: string]: boolean;
};

export default Vue.extend({
  name: 'RecommendationBlock',

  components: {
    ArticleCardSmall,
    TheLinkUi,
  },

  mixins: [
    FavoriteMethodsMixin,
    UpdatingViewsCountMixin,
    ArticleCardContainerMixin,
  ],

  inject: {
    area: {
      default () {
        return () => '';
      },
    },
  },

  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    items: {
      type: Array as PropType<IArticle[]>,
      required: true,
    },
    addLinkButton: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    articlesWithLoadedPixels: [] as number[],
    percentageContent: {
      instance: null as any,
      key: 'content',
      category: 'Прокрутка материала',
      thresholds: {
        25: false,
        50: false,
        75: false,
        100: false,
      } as thresholdOptions,
    },
  }),

  computed: {
    ...mapGetters({
      currentArticle: 'currentArticle',
    }),

    titleTag (): string {
      // кнопка используется только в блоке/виджете "подборка статей с кнопкой", в котором заголовок h2
      return this.addLinkButton ? 'h2' : 'div';
    },

    user (): IUser | null {
      const { user } = this.$store.state as IRootState;

      return user ?? null;
    },
    userIntegerId (): number | null {
      return this.user?.integer_id ?? null;
    },

    recommendationArticles (): IArticle[] {
      return this.items.filter((item) => {
        return item.id !== this.currentArticle?.id;
      });
    },

    getArchiveName (): string {
      const { name } = this.$route;

      switch (true) {
        case isHomePage(name):
          return 'главная';
        case isArchivePage(name) && !isTopPage(name):
          return 'архивы';
        case isTopPage(name):
          return 'лучшее';
        default:
          return '';
      }
    },
  },

  async mounted () {
    await this.$nextTick();
    this.initPercentage();

    const throttledOnScroll = throttle(100, this.onScroll);
    window.addEventListener('scroll', throttledOnScroll);

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', throttledOnScroll);
    });

    const includes = this.items.map(item => item.id);
    await this.$store.dispatch(FETCH_COMMENTS_COUNT, includes);
    this.$store.dispatch(FETCH_VIEWS_COUNT, includes);
    this.$store.dispatch(GET_AND_SET_FAVORITES, {
      params: {
        post_ids: includes.join(),
        limit: includes.length,
      },
    });
  },

  methods: {
    sendAnalyticsShowMore () {
      this.$sendYandexMetrika({
        level1: 'Клик_Показать еще_Виджет с кнопкой',
        level4: getFullUrl(this.link),
        level6: this.title,
      });
    },

    initPercentage (): void {
      const trackedElement = this.$refs[`recommendation-block--${this.id}`] as HTMLElement;

      if (trackedElement) {
        this.percentageContent.instance = new PercentageViewed(trackedElement);
      }
    },

    onScroll (): void {
      const contentPercent = this.percentageContent.instance
        ? this.percentageContent.instance.viewed
        : 0;

      if (contentPercent) {
        this.percentageSendAnalytics(
          this.percentageContent.thresholds,
          contentPercent,
        );
      }
    },

    percentageSendAnalytics (thresholds: thresholdOptions, newValue: any) {
      const viewedThresholds = Object.keys(thresholds).filter((threshold) => {
        const result = threshold <= newValue && !thresholds[threshold];
        return result;
      });

      const isHome = isHomePage(this.$route.name);
      const event = this.addLinkButton ? 'Прокрутка_виджет с кнопкой' : 'Прокрутка_рекомендательный блок';

      viewedThresholds.forEach((threshold) => {
        thresholds[threshold] = true;

        !isHome &&
          this.$sendAnalyticsSnowPlow({
            event_name: event,
            par3: String(threshold.toString()),
          });
      });
    },

    onAppear (article: IArticle): void {
      const testPixel = article?.testPixel;
      const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(
        article.id,
      );
      if (testPixel?.length && isNotLoadedPixel) {
        testPixel.forEach(url => this.$axios(url, { withCredentials: true }));
        this.articlesWithLoadedPixels.push(article.id);
      }
    },

    sendAnalyticsCommentEvent (article: IArticle) {
      const { url } = article;
      const element = this.addLinkButton ? 'виджет с кнопкой' : 'рекомендательный блок';

      this.$sendAnalyticsSnowPlow({
        event_name: 'Комментарии_переход к комментариям',
        par3: url,
      });

      this.$sendYandexMetrika({
        level1: 'Комментарии_переход к комментариям',
        level4: getFullUrl(url),
        level6: isHealthMain(this.$route.name) ? 'Главная здоровье' : isHomePage(this.$route.name) ? 'Главная' : element,
      });
    },

    sendAnalyticsFavoriteDeleteEvent (article: IArticle) {
      this.$sendAnalyticsSnowPlow({
        event_name: 'Избранное_удаление',
        par3: article.url,
        par4: this.getArchiveName || 'внутренние',
      });

      this.$sendYandexMetrika({
        level1: 'Избранное_удаление избранного',
        level4: getFullUrl(article.url),
        level6: this.title,
      });
    },

    sendAnalyticsFavoriteAddEvent (article: IArticle, folderName: string) {
      const element = this.addLinkButton ? 'виджет с кнопкой' : 'рекомендательный блок';

      this.$sendAnalyticsSnowPlow({
        event_name: 'Избранное_добавление избранного',
        par3: article.url,
        par4: this.getArchiveName || 'внутренние',
        par5: folderName,
      });

      this.$sendYandexMetrika({
        level1: 'Избранное_добавление избранного',
        level4: getFullUrl(article.url),
        level6: this.title,
        level8: element,
      });
    },

    sendAnalyticsCategoryClick ({ url }: IArticle, categoryName: string) {
      this.$sendAnalyticsSnowPlow({
        event_name: 'Выбор рубрики',
        par3: url,
        par4: categoryName,
      });

      this.$sendYandexMetrika({
        level1: 'Выбор рубрики',
        level4: getFullUrl(url),
        level5: categoryName,
        level6: this.title,
      });
    },

    sendAnalyticsArticleClick ({ url, teaserUrl }: IArticle, order: number) {
      const event = this.addLinkButton ? 'Выбор материала_Виджет с кнопкой' : 'Выбор материала_рекомендательный блок';

      this.$sendAnalyticsSnowPlow({
        event_name: event,
        par3: url,
        par5: String(order),
      });

      this.$sendYandexMetrika({
        level1: event,
        level4: getFullUrl(url),
        level5: order,
        level6: this.title,
        level8: teaserUrl ? 'Тизер' : 'Списочная карточка',
      });
    },
  },

});
