import { render, staticRenderFns } from "./ModalDeleteAccount.vue?vue&type=template&id=cfa6a992&scoped=true&lang=pug"
import script from "./ModalDeleteAccount.vue?vue&type=script&lang=ts"
export * from "./ModalDeleteAccount.vue?vue&type=script&lang=ts"
import style0 from "./ModalDeleteAccount.vue?vue&type=style&index=0&id=cfa6a992&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa6a992",
  null
  
)

export default component.exports