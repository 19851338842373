
import Vue, { PropOptions } from 'vue';
import ModalDeleteAccountPrompt from '~/components/ModalDeleteAccount/ModalDeleteAccountPrompt/ModelDeleteAccountPrompt.vue';
import ModalDeleteAccountConfirm from '~/components/ModalDeleteAccount/ModalDeleteAcountConfirm/ModalDeleteAcountConfirm.vue';
import PageScroll from '~/libs/PageScroll';

export default Vue.extend({
  name: 'ModalDeleteAccount',

  components: {
    ModalDeleteAccountPrompt,
    ModalDeleteAccountConfirm,
  },

  props: {
    isShow: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },

  data: () => ({
    component: 'ModalDeleteAccountPrompt',
  }),

  watch: {
    isShow (value) {
      if (value) {
        PageScroll.disable();
      } else {
        PageScroll.enable();
      }
    },
  },

  methods: {
    closeModal (): void {
      this.$emit('close');
    },

    onContinueDelete (): void {
      this.component = 'ModalDeleteAccountConfirm';
    },

    onClick (event: Event): void {
      if (event.target === this.$refs.modal) {
        this.closeModal();
      }
    },

    onDeleteConfirm (reason: any): void {
      this.$emit('delete', reason);
    },
  },
});
