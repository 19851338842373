
import Vue, { PropType } from 'vue';

import TheLink from '~/components/TheLink.vue';
import TestPixel from '~/components/TestPixel.vue';
import { getFullUrl, getRelativeLink } from '~/utils';

export default Vue.extend({
  name: 'NavMenuCategoriesItem',

  components: {
    TheLink,
    TestPixel,
  },

  props: {
    item: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
  },

  computed: {
    classes (): { [propName: string]: boolean } {
      return {
        'category-item__customized': Boolean(this.item.backgroundColor),
        'category-item__customized-text': Boolean(
          this.item.textColor && !this.item.backgroundColor,
        ),
        'category-item__active': this.isActive,
      };
    },

    cssVariablesLinkText (): Record<string, string> {
      const textColor = this.item.textColor;
      const backgroundColor = this.item.backgroundColor;
      if (textColor && backgroundColor) {
        return {
          '--text-color': textColor,
          '--bg-color': backgroundColor,
        };
      } else if (textColor) {
        return {
          '--text-color': textColor,
        };
      } else {
        return {
          '--bg-color': backgroundColor,
        };
      }
    },

    checkCustom (): boolean {
      return Boolean(this.item.backgroundColor || this.item.textColor);
    },

    getNavMenuItemClasses (): Record<string, boolean> {
      // /topics/android/
      return {
        'category-item__active-item': this.isActive,
      };
    },

    isActive (): boolean {
      const relativeLink = getRelativeLink(this.item.link);
      return this.$route.path === relativeLink;
    },
  },

  methods: {
    getAnalyticsLink (link: string): string {
      return getFullUrl(link);
    },
  },
});
