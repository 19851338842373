
import Vue, { PropOptions, PropType } from 'vue';

interface IIcon {
  [index: string]: string | undefined;

  readonly '--mobile'?: string;
  readonly '--tablet'?: string;
  readonly '--big-tablet'?: string;
  readonly '--desktop'?: string;
}

export default Vue.extend({
  props: {
    icon: {
      required: true,
      type: [String, Object] as PropType<string | IIcon>,
    },

    hoverColor: {
      type: String as PropType<string>,
      default: 'inherit',
    } as PropOptions<string>,

    tag: {
      type: String,
      default: 'span',
    } as PropOptions<string>,
  },

  computed: {
    cssVariables (): string {
      if (typeof this.icon === 'string') {
        return `
          --mobile: url(${require(`@/assets/sprite/svg/${this.icon}.svg`)});
          --tablet: var(--mobile);
          --big-tablet: var(--mobile);
        `;
      }

      if (typeof this.icon === 'object') {
        const keys = Object.keys(this.icon) as Array<keyof IIcon>;
        let cssString = '';
        const icon = this.icon as IIcon;

        keys.forEach((key: keyof IIcon) => {
          cssString += `
            ${key}: url(${require(`@/assets/sprite/svg/${icon[key]}.svg`)});
          `;
        });

        if (!('--tablet' in this.icon)) {
          cssString += '--tablet: var(--mobile); ';
        }

        if (!('--big-tablet' in this.icon)) {
          cssString += `--big-tablet: var(${
            this.icon['--tablet'] || this.icon['--mobile']
          }); `;
        }

        if (!('--desktop' in this.icon)) {
          cssString += `--desktop: var(${
            this.icon['--big-tablet'] ||
            this.icon['--tablet'] ||
            this.icon['--mobile']
          }); `;
        }

        return cssString;
      }
      return '';
    },
  },
});
