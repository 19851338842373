export default {
  comment_reply: 'ответил(а) вам под статьёй',
  comment_reply_recipe: 'ответил(а) вам под рецептом',
  email_verify: 'отправлено сообщение на почту для подтверждения email',
  email_confirm: 'подтвердил(а) новый адрес почты',
  comment_rated_like: 'поставил(а) +1 комментарию к статье',
  comment_rated_like_recipe: 'поставил(а) +1 комментарию к рецепту',
  comment_rated_dislike: 'поставил(а) -1 комментарию к статье',
  comment_rated_dislike_recipe: 'поставил(а) -1 комментарию к рецепту',
};
