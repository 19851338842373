
import Vue from 'vue';

export default Vue.extend({
  name: 'ModalDeleteAccountPrompt',

  data: () => ({
    title: 'Вы действительно хотите удалить этот аккаунт?',
    text: 'Профиль на Лайфхакере будет удалён. Вы не сможете его восстановить.',
    imagePath: require('./images/delete-account.svg'),
  }),

  computed: {
    promptImageStyle (): any {
      return this.imagePath
        ? {
          background: `url('${this.imagePath}')`,
        }
        : null;
    },
  },

  methods: {
    cancelClick (): void {
      this.$emit('cancel');
    },

    continueClick (): void {
      this.$emit('continue');
    },
  },

});
