// @ts-ignore
import shortid from 'shortid';
import { Dispatch } from 'vuex/types/index';
import { getEcho } from './utils.client';
import { FETCH_UNVIEWED_NOTIFICATIONS_COUNT, HANDLE_WS_EVENT, PUSH_BADGE, RESET_STORE } from '~/constants';
import { getSubId } from '~/utils';

type pushBadgePayload = {
    image?: string
    content?: string
    clickHandler?: Function
    isError?: boolean
}

export const connectPrivateChannel = (store: any, cb?: Function) => {
  const integerId = getSubId();
  if (integerId) {
    getEcho()
      .channel(`pusher_database_private-channel.${integerId}`)
      .listen('SocketPrivateEvent', (event: Event) => {
        store.dispatch(HANDLE_WS_EVENT, { event });
        if (cb) { cb(event); }
      });
  }
};

export const leavePrivateChannel = () => {
  const integerId = getSubId();
  if (integerId) {
    return getEcho().leaveChannel(
        `pusher_database_private-channel.${integerId}`,
    );
  }
};

export const fetchUnviewedNotificationsCount = (store: any) => {
  const integerId = getSubId();
  if (integerId) {
    return store.dispatch(FETCH_UNVIEWED_NOTIFICATIONS_COUNT);
  }
};

export const pushBadge = (storeDispatch: Dispatch, payload: pushBadgePayload) => {
  const { image, content, clickHandler, isError } = payload;
  return storeDispatch(PUSH_BADGE, {
    image,
    content,
    clickHandler,
    id: shortid.generate(),
    isError,
  });
};

export const pusherResetStore = (store: any) => store.commit(RESET_STORE);
