
import Vue from 'vue';
// @ts-ignore
import { mapGetters } from 'vuex';
import MascotSpeech from './MascotSpeech.vue';
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import { createScriptIfNeeded } from '~/utils/embeds';

export default Vue.extend({
  name: 'MascotFooter',

  components: {
    IntersectionObserver,
    MascotSpeech,
  },

  data () {
    return {
      animation: null as null | any,
      showMenu: false as boolean,
      hovered: false as boolean,
      containerHidden: false as boolean,
    };
  },

  computed: {
    ...mapGetters({
      mascot: 'mascotFooterInfo',
    }),

    containerClass (): Record<string, boolean> {
      return {
        'container--hovered': this.hovered || this.showMenu,
      };
    },

    classHoveredAnimation (): Record<string, boolean> {
      return {
        'mascot-footer__animation--hovered': this.hovered || this.showMenu,
      };
    },
  },

  watch: {
    $route () {
      this.showMenu = false;
      this.containerHidden = false;
      this.hovered = false;
    },
  },

  async mounted () {
    await createScriptIfNeeded('/js/lottie.min.js');

    const animationData = await import('~/lottie-animations/lazeyka-top-crop.json');

    this.$nextTick(() => {
      this.animation = window?.lottie?.loadAnimation({
        container: this.$refs.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData,
        initialSegment: [0, 450],
      });
    });
  },

  methods: {
    playAnimation () {
      if (this.animation) {
        this.animation.play();
        this.animation.loop = true;
      }
    },

    handleClose () {
      this.containerHidden = true;
    },

    handleMouseover () {
      if (this.showMenu) {
        return;
      }

      if (this.animation.currentFrame < 350) {
        this.animation.pause();
      } else {
        this.animation.loop = false;
      }

      this.hovered = true;
    },

    handleMouseleave () {
      this.playAnimation();
      this.hovered = this.showMenu ?? false;
    },

    handleClick () {
      this.playAnimation();
      this.showMenu = true;
    },
  },
});
