
import Vue from 'vue';

import { getPushNotificationsManager } from '~/plugins/push-notifications.client';
import isNotificationsSupported from '~/utils/isNotificationsSupported';

export default Vue.extend({
  name: 'PushControlButton',

  computed: {
    pushControlTitle (): string {
      return this.$store.state.isSubscribedToWebPushNotifications
        ? 'Вы подписаны на push'
        : 'Вы не подписаны на push';
    },

    isPushControlVisible (): boolean {
      return isNotificationsSupported();
    },
  },

  methods: {
    handlePushControlClick (): void {
      const pushNotificationsManager = getPushNotificationsManager(
        this.$store,
        this.$pushApi,
      );

      if (this.$store.state.isSubscribedToWebPushNotifications) {
        pushNotificationsManager.unsubscribe();
      } else {
        pushNotificationsManager.subscribe();
      }
    },
  },
});
